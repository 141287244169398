import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <Container fluid className="sticky-top bg-white">
      <nav className="navbar sticky-top navbar-expand-lg">
        <div className="d-flex align-items-center justify-content-between w-100">
          {/* <NavLink className="navbar-brand text-dark" to=""> */}
          {/* <img src="assets/images/header-logo.png" id="logsedf" alt="" /> */}
          <div className="cursor-pointer">
            <h1 className="py-2">Cabtify Driver</h1>
          </div>
          {/* </NavLink> */}
          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          <div className="nav-right-content">
            {/* <NavLink to="/login" className="info-bar-item-two">
                    <div className="author-nav-content">
                      <span>Book a Ride</span>
                    </div>
                  </NavLink> */}
            {/* <NavLink to="" className="info-bar-item-two">
                    <div className="author-nav-content">
                      <span> Sign Up</span>
                    </div>
                  </NavLink> */}
          </div>
        </div>
      </nav>
      <hr className="m-0" />
    </Container>
  );
}

export default Header;
