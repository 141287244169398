import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Stepper({ steps, activeStep, handleNext, handleBack, children }) {
  return (
    <div className="sticky-progress-stepper">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center mt-4 ">
              <div>
                <h3 className=" mb-0  text-capitalize font-weight-bold">
                  {steps[activeStep]}
                </h3>
              </div>
              <div>
                {/* {activeStep !== 0 && (
                <Button variant="secondary" onClick={handleBack}>
                  Back
                </Button>
              )} */}
                {children}
              </div>
            </div>
            <div className="progress mt-3">
              <div
                className={`progress-bar bg-success`}
                style={{
                  width: `${(activeStep / (steps.length )) * 100}%`,
                }}
              ></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Stepper;
