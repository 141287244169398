import React from "react";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
// import { Link } from "react-router-dom";
import Table from "../../components/table";
import { useEffect } from "react";
import {
  asyncGetWallet,
  asyncGetWalletTransactions,
} from "../../redux/slices/walletSlice";
import moment from "moment";
import MobileMoneyDetailsModal from "../../components/modals/MobileMoneyDetailsModal";
// import MobileMoneyDetailsModal from '../../components/modals/MobileMoneyDetailsModal';

function Wallet() {
  const columns = [
    // {
    //   displayName: "Passenger Name",
    //   displayField: (e) => (
    //     <>
    //       {e.passenger?.firstName} {e.passenger?.lastName}
    //     </>
    //   ),
    //   searchable: true,
    // },
    {
      displayName: "Amount",
      displayField: (e) => (
        <>
          {e.currency} {e.amount}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Type",
      displayField: (e) => <>{e?.type}</>,
      searchable: true,
    },
    {
      displayName: "Payment Method",
      displayField: (e) => <>{e?.paymentMethod}</>,
      searchable: true,
    },
    {
      displayName: "Description",
      displayField: (e) => <>{e?.description}</>,
      searchable: true,
    },

    {
      displayName: "Time",
      displayField: (e) => (
        <>{moment(e?.updatedAt).format("hh:mm a @ DD MMM, YYYY")}</>
      ),
    },
    {
      key: "status",
      displayName: "Status",
    },
  ];

  const dispatch = useDispatch();
  // const [showPopup, setShowPopup] = useState(false);
  const [showMobileMoneyPopup, setShowMobileMoneyPopup] = useState(false);
  const wallet = useSelector((state) => state.wallet);

  const { data, transactions } = wallet;
  const { list, isLoading } = transactions;

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  const closeMobileMoneyPopup = () => {
    setShowMobileMoneyPopup(false);
  };

  useEffect(() => {
    dispatch(asyncGetWallet());
    dispatch(asyncGetWalletTransactions());
  }, [dispatch]);

  return (
    <>
      <div class="row">
        {data ? (
          <div class="col-12 text-center">
            <div class="card card-block">
              <div class="card-header mb-4">
                <h5>Your Balance</h5>
              </div>
              <div class="card-body">
                <h3>
                  {data.currency} {data.amount}
                </h3>
              </div>
              <div className=" mb-4 d-flex gap-4 justify-content-center">
                {/* <CustomButton
                  type="submit"
                  title="Withdraw"                        
                  class="btn btn-primary"
                  onClick={() => setShowPopup(true)}
                /> */}
                <CustomButton
                  type="submit"
                  title="Mobile Money Details"
                  class="btn btn-primary"
                  onClick={() => setShowMobileMoneyPopup(true)}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {transactions ? (
          <div className="py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className="page-title">Transactions</h4>{" "}
              </div>
            </div>
            {/* <TransactionsTable /> */}
            <Table dataSource={list} isLoading={isLoading} columns={columns} />
          </div>
        ) : (            
          <></>
        )}
      </div>
       
      <MobileMoneyDetailsModal
        show={showMobileMoneyPopup}
        closePopup={closeMobileMoneyPopup}   
      />
    </>
  );
}

export default Wallet;
