import React from "react";
// import { NavLink } from "react-router-dom";
import RideRequestsTable from "./RideRequestsTable.js";
// import PageSubTitle from "../../components/pageSubTitle";

function RideRequestsPage() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Ride Requests List</h4>
              </div>
            </div>
            <RideRequestsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RideRequestsPage;
