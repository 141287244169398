import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import socket from "../../services/socket";
import { addRideRequest } from "../../redux/slices/rideRequestSlice";
import { notifyFailure, notifySuccess } from "../../components/toast";
import { cancelRide, setRideDetails } from "../../redux/slices/rideSlice";
import { updateUserLocation } from "../../redux/slices/locationSlice";
import RideRequestPopup from "../../components/modals/rideRequestModal";

function AdminLayout({ children }) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);

  const { userInfo } = auth;

  const [newRideRequest, setNewRideRequest] = useState(false);
  const [rideRequestTimeout, setRideRequestTimeout] = useState(45);

  const [rideRequestDetails, setRideRequestDetails] = useState(null);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  // const [notificationSound] = useState(
  //   new Audio("/assets/sounds/ride-request-sound.wav")
  // );

  const connectSocket = () => {
    try {
      if (socket?.connect && !socket.connected && navigator?.onLine) {
        socket.connect();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("socket connected==>", socket.connected);
    if (socket.conected && userInfo) {
      socket.emit("driver-connected", userInfo._id);
    }
  }, [userInfo]);

  useEffect(() => {
    const notificationSound = new Audio(
      "/assets/sounds/ride-request-sound.wav"
    );

    if (userInfo) {
      connectSocket();
      socket.on("connect", () => {
        // console.log("socket connected");
        socket.emit("driver-connected", userInfo._id);
        socket.on("new-ride-request", (data) => {
          console.log("new ride request==>", data);
          notificationSound.play();
          setNewRideRequest(true);
          setRideRequestDetails(data.rideRequest);
          setRideRequestTimeout(data.requestTimeout ? data.requestTimeout : 45);
        });
      });

      socket.on("ride-accepted-by-other", (data) => {
        console.log("ride accepted by other==>", data);
      });
      socket.on("ride-request-timeout", (data) => {
        console.log("ride req timeout==>", data);

        dispatch(
          addRideRequest({ ...rideRequestDetails, status: "Request Timeout" })
        );
        setRideRequestDetails(null);
        setNewRideRequest(false);
        notifySuccess("Ride request timeout!");
      });

      socket.on("ride-cancelled", (data) => {
        console.log("ride cancelled==>", data);
        dispatch(cancelRide());
        notifySuccess("Ride cancelled by passenger!");
      });

      socket.on("disconnect", () => {
        console.log("socket disconnected");
        socket.emit("driver-disconnected", userInfo._id);
      });
      // const intervalFunction = () => {
      //   // Your code here
      //   console.log("This runs every 5 seconds");

      //   // socket.emit("update-driver-location");
      // };

      // Use setInterval to call the function every 5 seconds (5000 milliseconds)
      // const intervalId = setInterval(intervalFunction, 5000);
      return () => {
        // clearInterval(intervalId);

        socket.emit("driver-logout", userInfo._id);
        socket.emit("driver-disconnected", userInfo._id);

        socket.disconnect();
      };
    }
  }, [dispatch, userInfo, rideRequestDetails]);

  // Comment for testing
  useEffect(() => {
    if (userInfo && location.coordinates) {
      if (location.coordinates[0] !== 0 && location.coordinates[1] !== 0)
        socket.emit("update-driver-location", {
          driverId: userInfo._id,
          coordinates: location.coordinates,
        });
    }
  }, [userInfo, location.coordinates]);

  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        // function (position) {
        //   const latitude = position.coords.latitude;
        //   const longitude = position.coords.longitude;
        //   setLocation({ latitude, longitude });
        // }
        handleSuccess,
        handleError
        // function (error) {
        //   setError(error.message);
        // }
      );
      // console.log("navig==>", navigator.geolocation);

      // To stop tracking the location when the component unmounts
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      console.log("Geolocation is not available");
      // dispatch(hasLocationPermission(false));
    }
  }, [navigator.geolocation]);

  const handleSuccess = (position) => {
    // dispatch(hasLocationPermission(true));

    // console.log(position);
    const { latitude, longitude } = position.coords;
    if (
      location.coordinates[0] !== latitude &&
      location.coordinates[1] !== longitude
    ) {
      console.log("diff loc==>", latitude, longitude);
      dispatch(updateUserLocation([latitude, longitude]));
    }
    console.log("same loc==>", latitude, longitude);
    // dispatch(asyncGetUserLocation({ latitude, longitude }));
  };

  const handleError = (err) => {
    console.log(err);
    // dispatch(hasLocationPermission(false));
  };

  // Uncomment for testing
  // const [tempCoordinates, setTempCoordinates] = useState([
  //   24.9266176, 67.0695424,
  // ]);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTempCoordinates((prev) => [prev[0] - 0.0002, prev[1] + 0.0009]);
  //   }, 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // console.log("temp coor==>", tempCoordinates);

  // useEffect(() => {
  //   if (userInfo && tempCoordinates) {
  //     socket.emit("update-driver-location", {
  //       driverId: auth.userInfo._id,
  //       coordinates: tempCoordinates,
  //     });
  //     dispatch(updateUserLocation(tempCoordinates));
  //   }
  // }, [userInfo, tempCoordinates]);

  // Till here
  const rejectRideHandler = () => {
    setIsRejectLoading(true);
    // notifySuccess("Rejecting a request!");
    socket.emit(
      "reject-ride",
      {
        requestIdentifier: rideRequestDetails.requestIdentifier,
        driver: auth.userInfo._id,
      },
      (res) => {
        if (res.success) {
          setIsRejectLoading(false);
          dispatch(
            addRideRequest({ ...rideRequestDetails, status: "Rejected" })
          );
          setRideRequestDetails(null);
          setNewRideRequest(false);
          notifySuccess("Ride request rejected successfully!");
        } else {
          notifyFailure("Something went wrong!");
        }
      }
    );
  };
  console.log("ride req det==>", rideRequestDetails);

  const acceptRideHandler = () => {
    setIsAcceptLoading(true);

    console.log("accepting ride", {
      requestIdentifier: rideRequestDetails.requestIdentifier,
      driver: auth.userInfo._id,
    });
    socket.emit(
      "accept-ride-v2",
      {
        requestIdentifier: rideRequestDetails.requestIdentifier,
        driver: auth.userInfo._id,
      },
      (res) => {
        console.log("accept ride response==>", res);
        if (res.success) {
          setIsAcceptLoading(false);
          dispatch(
            addRideRequest({ ...rideRequestDetails, status: "Accepted" })
          );
          dispatch(setRideDetails(res.ride));
          setNewRideRequest(false);
          notifySuccess("Ride accepted successfully!");
        } else {
          notifyFailure("Something went wrong!");
        }
        // setRideRequestDetails(null);
      }
    );
  };

  if (!userInfo) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div id="loading" style={{ display: "none" }}>
        <div id="loading-center"></div>
      </div>
      <Header />
      <Sidebar />

      <div id="remoteModelData" className="modal fade" role="dialog" />
      <div className="content-page">{children}</div>
      <Footer />

      {rideRequestDetails && newRideRequest && (
        <RideRequestPopup
          show={newRideRequest}
          rideRequestDetails={rideRequestDetails}
          rideRequestTimeout={rideRequestTimeout}
          rejectRideHandler={rejectRideHandler}
          acceptRideHandler={acceptRideHandler}
          // requestTimeoutHandler={requestTimeoutHandler}
          isAcceptLoading={isAcceptLoading}
          isRejectLoading={isRejectLoading}
        />
      )}
    </div>
  );
}

export default AdminLayout;
