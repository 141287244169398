import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useSelector } from "react-redux";

function RideRequestPopup({
  show,
  acceptRideHandler,
  rejectRideHandler,
  requestTimeoutHandler,
  rideRequestDetails,
  rideRequestTimeout,
  isAcceptLoading,
  isRejectLoading,
}) {
  // const settings = useSelector((state) => state.settings.data);
  // const { rideSettings } = settings;

  const [timeLeft, setTimeLeft] = useState(rideRequestTimeout); // Initial time left in seconds

  useEffect(() => {
    let timer;

    // Start the countdown when the modal opens
    if (show) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000); // Update every 1 second
    } else {
      // Clear the timer when the modal is closed
      clearInterval(timer);
      setTimeLeft(rideRequestTimeout); // Reset the timer
    }

    return () => {
      // Cleanup: Clear the interval when the component unmounts
      clearInterval(timer);
    };
  }, [show]);

  // Format the time left as "mm:ss"
  const formattedTimeLeft = `${Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, "0")}:${(timeLeft % 60).toString().padStart(2, "0")}`;

  // useEffect(() => {
  //   if (timeLeft <= 0) {
  //     requestTimeoutHandler();
  //   }
  // }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(rideRequestTimeout);
  }, [rideRequestTimeout]);

  return (
    <Modal show={show} centered>
      <div className="small-modal">
        <Modal.Header className="d-flex justify-content-between">
          <h5>Ride Request Details</h5>
          <h6 className="text-danger">{formattedTimeLeft}</h6>
        </Modal.Header>
        <Modal.Body>
          {/* Passenger Information */}
          <div className="mb-3">
            <h6>Passenger Information:</h6>
            <div className="d-flex align-items-center">
              <img
                src={"/assets/images/passenger.png"}
                width={100}
                alt="Passenger"
                className="mr-3"
              />
              <div>
                <p className="mb-0">
                  <span className="fw-bold">Name:</span>{" "}
                  {rideRequestDetails.passenger.firstName}{" "}
                  {rideRequestDetails.passenger.lastName}
                </p>
                {/* <p className="mb-0">Email: john@example.com</p> */}
                <p className="mb-0">
                  <span className="fw-bold">Phone:</span>{" "}
                  {rideRequestDetails.passenger.phone}
                </p>
              </div>
            </div>
          </div>

          {/* Pickup and Dropoff Locations */}
          <div className="mb-3">
            <h6>Pickup Location:</h6>
            <div className="d-flex align-items-start">
              <img
                src={"/assets/images/location-icon.png"}
                width={20}
                alt="Location"
                className="mr-2 mt-1"
              />
              <p className="mb-0">
                {rideRequestDetails.pickup.address.formattedAddress}
              </p>
            </div>
          </div>
          <div className="mb-3">
            <h6>Dropoff Location:</h6>
            <div className="d-flex align-items-center">
              <img
                src={"/assets/images/location-icon.png"}
                width={20}
                alt="Location"
                className="mr-2 mt-1"
              />
              <p className="mb-0">
                {rideRequestDetails.dropoff.address.formattedAddress}
              </p>
            </div>
          </div>

          {/* Fare and Distance */}
          <div className="mb-3">
            <h6>Estimated Fare:</h6>
            <p>
              {rideRequestDetails.fareDetails?.totalFare}{" "}
              {rideRequestDetails.fareDetails?.currency}
            </p>
          </div>
          <div>
            <h6>Estimated Distance / Duration:</h6>
            <p>
              {rideRequestDetails.distance} / {rideRequestDetails.duration}
            </p>
          </div>
        </Modal.Body>{" "}
        <Modal.Footer>
          <CustomButton
            title="Accept"
            className="btn btn-success"
            isLoading={isAcceptLoading}
            onClick={acceptRideHandler}
          />
          <CustomButton
            title="Reject"
            className="btn btn-danger"
            isLoading={isRejectLoading}
            onClick={rejectRideHandler}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default RideRequestPopup;
